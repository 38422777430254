import { PROJECT_MODE, TESTING, STAGING, PRODUCTION } from "./Controller";
const URL_PREFIX_TESTING =
  "http://localhost:5000/medalla-test/europe-west3/portal";
const URL_PREFIX_STAGING =
  "https://europe-west3-medalla-test.cloudfunctions.net/portal";
const URL_PREFIX_PRODUCTION =
  "https://europe-west3-medalla-prod.cloudfunctions.net/portal";

let DOMAIN = "https://www.jobs-medalla.se";

let URL_PREFIX = TESTING;

export const API_TIMEOUT = 10000;

switch (PROJECT_MODE) {
  case TESTING:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
  case STAGING:
    URL_PREFIX = URL_PREFIX_STAGING;
    break;
  case PRODUCTION:
    DOMAIN = "https://app.medalla.se";
    URL_PREFIX = URL_PREFIX_PRODUCTION;
    break;
  default:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
}

export const VALIDATE_JOB_RECOMMENDATION = URL_PREFIX + "/job/validateRecommendation";
export const FETCH_JOB = URL_PREFIX + "/job/job";
export const REJECT_JOB_INVITATION = URL_PREFIX + '/job/rejectJobInvitation';
export const SUBMIT_JOB_CANDIDATE = URL_PREFIX + '/job/submitCandidate';
export const UPDATE_JOB_STAT_ACTION = URL_PREFIX + "/stats/update_job_action";
export const INSIGHTS_CONFIGURATION = URL_PREFIX + "/insights/configuration";
export const WORKPLACE_AVERAGE_SCORE = URL_PREFIX + "/insights/average_score";
export const RANKING_POSITION = URL_PREFIX + "/insights/ranking_position"
