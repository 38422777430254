export const svLocale = {
  has_recommended_you_for_a_Job:
    "Hej, {{firstName}} har rekommenderat dig för ett jobb",
  looking_for: "Söker",
  employment_type: "Arbetsform",
  address: "Adress",
  would_you_like_to_be_contacted:
    "Vill du bli kontaktad av någon från vårdenheten för att få mer information om jobbet?",
  continue: "Fortsätt",
  not_interested: "Inte intresserad",
  submit: "Skicka",
  we_appreciate_interest_candidate_form_1: "Kul att du är intresserad!",
  we_appreciate_interest_candidate_form_2:
    "Ange dina uppgifter så hör vi av oss.",
  candidate_consent:
    "Jag tillåter att dela mina kontaktuppgifter till '{{firstName}} {{lastName}}' på '{{wp_name}}'",
  candidate_consent_2: "Mina uppgifter kommer delas med '{{wp_name}}'",
  candidate_consent_checkbox:
    "Jag tillåter att {{firstName}} får följa min rekryteringsprocess",
  could_not_complete_request: "Kunde inte slutföra åtgärd",
  invalid_information: "Ange giltig information",
  already_submitted: "Redan skickat",
  candidate_submission_positive:
    "Hej {{firstName}}, tack för din intresseanmälan till jobbet. Hämta Medalla för att få mer information om arbetsplatsen och kunna chatta med rekryterande chef.",
  candidate_submission_negative:
    "Hämta Medalla appen för att förbättra ditt nätverk inom vården och upptäcka bra arbetsplatser.",
  thanks_medalla: "Thanks,\nTeam Medalla",
  download_app: "Hämta Medalla",
  job_tips_by_downloading_app:
    "Hämta Medalla för att see andra abetsmöjligheter",
  job_disabled:
    "Sorry, detta jobb är inaktiverat av {{name_obj}}. Se andra möjligheter i Medalla appen.",
  full_name: "För- och efternamn",
  email: "E-post",
  phone_number: "Telefon",
  back: "backa",
  next: "Nästa",
  start_quiz: "Starta",
  share_quiz: "Dela detta quiz med dina vänner",
  load_more: "Ladda mer",
  strongly_agree: "Instämmer helt",
  agree: "Instämmer delvis",
  neutral: "Neutral",
  disagree: "Instämmer inte",
  strongly_disagree: "Instämmer inte alls",
  rank: "Ranking",
  workplace: "Arbetsplats",
  location: "Plats",
  score: "Totalt",
  score_result:
    "Du har skattat din arbetsmiljö till {{result}} vilket är {{resultText}} än genomsnittet i Sverige.",
  get_insights: "Få inblick i arbetsplatser",
  get_app: "Hämta appen",
  candidate_consent_checkbox_c2: "Nej, håll mina framsteg hemliga",
  unavailable_job:"Jobbet som {{role}} på {{workplaceName}} är inte tillgängligt.",
  job_deleted:"Det här jobbet är inte tillgängligt.",
  explore_medalla:"Kolla in Medalla",
};
