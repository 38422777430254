import React, { Component, useState } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  Grid,
  Icon,
  Image,
  Message,
  Radio,
  Segment,
} from "semantic-ui-react";
import { Buffer } from "buffer";
import axios from "axios";
import {
  REJECT_JOB_INVITATION,
  SUBMIT_JOB_CANDIDATE,
  UPDATE_JOB_STAT_ACTION,
  VALIDATE_JOB_RECOMMENDATION,
} from "../../utils/Controller/URLConstants";
import {
  DownloadFromAppStore,
  DownloadFromPlayStore,
  MedallaButton,
} from "../Custom/CustomButtons";
import {
  validateContactField,
  validateEmail,
} from "../../utils/FieldValidation";
import { connect } from "react-redux";
import { closeModal, openModal } from "../../redux/Modals/ModalActions";
import { MODAL_CUSTOM_MESSAGE } from "../Modals/MessageModal";
import { MEDALLA_TEAL } from "../../utils/Theme/ThemeConstants";
import { withTranslation, useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import * as randomstring from "randomstring";
import { JOB_STAT_ACTIONS } from "../../utils/Constants/stats";
import {
  AID_KIT,
  FULL_LOGO_ICON,
  JOB_UNAVAILABLE,
  LOTTIE_MEDALLA,
} from "../../utils/Constants/assets";
import ReactLinkify from "react-linkify";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import Lottie from "react-lottie";
import * as animationData from "./lottie/medalla.json";

const mapStateToProps = (state) => {
  return {};
};

const actions = {
  closeModal,
  openModal,
};

class CandidateApplyForm extends Component {
  state = {
    sessionId: null,
    actions: [],
    linkData: null,
    job: null,
    user: null,
    error: null,
    consent: 0,
    isJobInvitation: false,
    jobClosed: false,
    timerOver: false,
    showProgressOptions: true,
  };

  //http://localhost:3000/candidate/apply/?preview=eyJqb2IiOiJqb2ItMjQxQTM1S2Y2ViIsInVpZCI6Iml6Y3ZYZnpyWWphMHFPWVB6WnNBQ2h3NzZyTTIiLCJwdXJwb3NlIjoiam9iX2NhbmRpZGF0ZV9mb3JtIiwibWVzc2FnZSI6Ik15IGpvYiBpcyBoZXJlIHdlbGwgZG9uZSAifQ%3D%3D
  decodePreview(encodedString) {
    try {
      const buff = Buffer.from(encodedString, "base64");
      const str = buff.toString("utf8");
      return JSON.parse(str);
    } catch (e) {
      console.log(e);
    }
    return;
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // window.history.pushState(null, "", window.location.href.split("?")[0]);
    const previewData = searchParams.get("preview");
    if (previewData) {
      const jsonData = this.decodePreview(previewData);
      if (jsonData && jsonData["purpose"]) {
        const isJobInvitation = jsonData["purpose"] === "job_invitation";
        const showProgressOptions = jsonData["showProgressOptions"] ?? true;
        console.log(jsonData);
        this.setState({
          linkData: jsonData,
          isJobInvitation: isJobInvitation,
          showProgressOptions: showProgressOptions,
          // consent: showProgressOptions ? 0 : 1,
        });
        this.getValidatedRecommendationData(
          jsonData["job"],
          jsonData["uid"],
          jsonData["invitationTo"]
        );
      } else {
        this.setState({
          error: "Invalid Information",
        });
      }
    } else {
      this.setState({
        error: "Invalid Information",
      });
    }
    setTimeout(() => {
      this.setState({
        timerOver: true,
      });
    }, 4000);
  }

  getValidatedRecommendationData = async (jobID, userID, invitationTo) => {
    try {
      let QUERY_URL = VALIDATE_JOB_RECOMMENDATION;
      const response = await axios.post(QUERY_URL, {
        token: userID,
        job: jobID,
        bp: "1",
        recommendationFor: invitationTo,
      });

      if (response.data["status"] === 0) {
        let jobData = response.data["job"];
        let jobClosed = false;
        if (jobData) {
          jobClosed = jobData["status"] !== "open";
        }
        this.setState({
          job: jobData,
          jobClosed: jobClosed,
          user: response.data["user"],
          sessionId: randomstring.generate({
            length: 14,
            charset: "alphanumeric",
          }),
          actions: [],
        });
      } else if (response.data["status"] === 2) {
        this.setState({
          jobClosed: true,
        });
      }
    } catch (e) {
      this.setState({
        error: "Unable to load Information",
      });
      console.log(e);
    }
    return null;
  };

  rejectInvitation = async () => {
    try {
      const linkData = this.state.linkData;
      if (linkData && this.state.isJobInvitation) {
        const response = await axios.post(REJECT_JOB_INVITATION, {
          token: linkData["uid"],
          job: linkData["job"],
          bp: "1",
          email: linkData["invitationTo"],
        });

        if (response.data["status"] === 0) {
          // Invitation Rejected
        } else {
        }
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  setConsent = (value) => {
    this.setState({
      consent: value,
    });
  };

  pushAction = async (action) => {
    const { sessionId, actions, linkData } = this.state;
    if (sessionId && Object.keys(JOB_STAT_ACTIONS).includes(action)) {
      const jobID = linkData["job"];
      const userID = linkData["uid"];
      actions.push(action);
      const response = await axios.post(UPDATE_JOB_STAT_ACTION, {
        token: userID,
        job: jobID,
        actions: JSON.stringify(actions),
        session: sessionId,
      });
      console.log(response.data);
    }
  };

  getContentScreen() {
    const {
      job,
      user,
      consent,
      linkData,
      isJobInvitation,
      showProgressOptions,
    } = this.state;
    const { openModal, t } = this.props;
    switch (consent) {
      case 1:
        return (
          <CandidateForm
            job={job}
            user={user}
            setConsent={this.setConsent}
            showProgressOptions={showProgressOptions}
            openModal={openModal}
            linkData={linkData}
            isJobInvitation={isJobInvitation}
            pushAction={this.pushAction}
            t={t}
          />
        );
      case 2:
        return <FinalMessage user={user} success={false} job={job} />;
      default:
        return (
          <ConsentScreen
            job={job}
            user={user}
            rejectInvitation={this.rejectInvitation}
            setConsent={this.setConsent}
            pushAction={this.pushAction}
            linkData={linkData}
          />
        );
    }
  }

  render() {
    const { job, consent, error, jobClosed, timerOver } = this.state;

    const loadedContent = job;

    if ((!loadedContent && !error && !jobClosed) || !timerOver) {
      return (
        <Grid centered style={{ minHeight: "100vh" }}>
          <Grid.Column verticalAlign="middle" textAlign="center">
            <Lottie
              options={{
                animationData: animationData,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={isMobile ? "32vw" : "12vw"}
              width={isMobile ? "32vw" : "12vw"}
            />
          </Grid.Column>
        </Grid>
      );
    }

    if (jobClosed) {
      return <JobUnavailable job={job} />;
    }

    return (
      <div>
        <Segment
          basic
          loading={!loadedContent && !error}
          style={{ minHeight: "100vh" }}
        >
          {error && <Message content={error} />}
          {loadedContent ? this.getContentScreen(consent) : <div />}
        </Segment>
      </div>
    );
  }
}

function JobUnavailable(props) {
  const { job = {} } = props;
  const { t } = useTranslation();
  const workplaceDetails = job ? job["workplaceDetails"] : {};
  return (
    <Grid verticalAlign="middle" textAlign="center" style={{ height: "100vh" }}>
      <Grid.Column>
        <a href="https://onelink.to/medalla" target="_blank" rel="noreferrer">
          <Image src={FULL_LOGO_ICON} centered size="small" />
        </a>

        {job ? (
          <div className="medium-text poppins-regular">
            {t("unavailable_job", {
              workplaceName: workplaceDetails["name"] ?? "",
              role: job["profession"] ?? "",
            })}
          </div>
        ) : (
          <div className="medium-text poppins-regular">
            {" "}
            {t("job_deleted")}{" "}
          </div>
        )}
        <br />
        <Image src={JOB_UNAVAILABLE} centered size="medium" />
        <br />
        <a href="https://onelink.to/medalla" target="_blank" rel="noreferrer">
          <MedallaButton style={{ color: "white" }}>
            {t("explore_medalla")}
          </MedallaButton>
        </a>
      </Grid.Column>
    </Grid>
  );
}

export default withTranslation()(
  connect(mapStateToProps, actions)(CandidateApplyForm)
);

// function SpaceElement() {
//   return isMobile ? "" : <br />;
// }

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  let resultText = isReadMore ? text.slice(0, 120) : text;
  return (
    <p className="medium-text noto-sans-regular">
      <ReactLinkify>{resultText}</ReactLinkify>
      {text.length > 120 && (
        <span
          style={{ color: MEDALLA_TEAL }}
          onClick={toggleReadMore}
          className="read-or-hide"
        >
          {isReadMore ? "...read more" : " show less"}
        </span>
      )}
    </p>
  );
};

function ConsentScreen(props) {
  const { setConsent, job, user, linkData, pushAction, rejectInvitation } =
    props;
  const workplaceDetails = job["workplaceDetails"];
  const workfields = workplaceDetails["workfields"];
  const jobType = job["employmentTypes"] ?? [];
  const message = linkData["message"] ?? "";
  // const address = workplaceDetails["address"] ?? "Not Specified";
  const jobDescription = (job["description"] ?? "").trim();
  const { t } = useTranslation();
  const roleForSender = user
    ? (user["workplaceRoles"] ?? {})[workplaceDetails["workPlaceId"]] ?? ""
    : "";
  const logoURL = (workplaceDetails["logoUrl"] ?? "").trim();
  return (
    <Segment basic style={{ minHeight: "100vh", padding: "0px" }}>
      <Grid centered stretched style={{ height: "100%" }}>
        <Grid.Column
          mobile={16}
          computer={8}
          textAlign="center"
          verticalAlign={isMobile ? "top" : "middle"}
          stretched
        >
          {/* <Image
            src={"/assets/logo.png"}
            size={isMobile ? "small" : "medium"}
            centered
            style={{ marginBottom: "10px" }}
          />

          <SpaceElement /> */}
          {user && (
            <Grid>
              <Grid.Column computer={3} mobile={4} textAlign="right">
                <Image
                  src={user["profileImageUrl"]}
                  floated="left"
                  size="medium"
                  style={{
                    borderRadius: "20vw",

                    width: isMobile ? "15vw" : "70px",
                    height: isMobile ? "15vw" : "70px",
                    objectFit: "cover",
                    backgroundColor: "#D3D3D3",
                  }}
                />
              </Grid.Column>
              <Grid.Column
                computer={12}
                mobile={11}
                textAlign="left"
                verticalAlign="middle"
              >
                <div>
                  <span className={"medium-text poppins-bold"}>
                    {user["firstName"]}
                  </span>
                  <br />
                  <span
                    className={"medium-text noto-sans-light"}
                    style={{ color: "grey" }}
                  >
                    {" "}
                    {roleForSender}
                  </span>
                </div>
              </Grid.Column>
            </Grid>
          )}

          {message.trim() !== "" && (
            <div style={{ textAlign: "left", marginTop: "1rem" }}>
              <ReadMore children={linkData["message"]} />
            </div>
          )}

          {/* <SpaceElement /> */}

          {/* <div
            className={isMobile ? "large-text" : "medium-text"}
            style={{ marginTop: "10px" }}
          >
            {t("has_recommended_you_for_a_Job", {
              firstName: user["firstName"],
            })}
          </div> */}

          {/* {message.trim() !== "" && (
            <div
              className={isMobile ? "large-text" : "medium-text"}
              style={{ marginTop: "2vh", marginBottom: "1vh" }}
            >
              {linkData["message"]}
            </div>
          )} */}
          <Card fluid>
            <Card.Content style={{ textAlign: "left" }}>
              <div className="medium-text noto-sans-regular">
                {" "}
                <Icon name="id badge" />
                {job["profession"]}
              </div>
              <div
                className="medium-text noto-sans-light"
                style={{ marginTop: "4px", color: "grey" }}
              >
                <Icon name="suitcase" />
                {jobType.join(" , ")}
              </div>
              <div
                className="medium-text noto-sans-light"
                style={{ marginTop: "4px", color: "grey" }}
              >
                <Icon name="hospital" />
                {workfields.join(" , ")}
              </div>
            </Card.Content>
            <Card.Content style={{ textAlign: "left" }}>
              <Image
                size="mini"
                bordered
                floated="left"
                rounded
                src={logoURL.length === 0 ? AID_KIT : logoURL}
                style={{
                  width: isMobile ? "10vw" : "4vw",
                  height: isMobile ? "10vw" : "4vw",
                  borderRadius: "0.5rem",
                  marginRight: "2%",
                }}
              />
              <div className="medium-text noto-sans-regular">
                {workplaceDetails["name"]}
              </div>
              <div className="medium-text noto-sans-light">
                {workplaceDetails["orgName"]}
              </div>
              <div className="medium-text noto-sans-light">
                <Icon name="map pin" />
                {workplaceDetails["address"]}
              </div>
            </Card.Content>
            {/* <Card.Content>
              <Grid>
                <Grid.Column textAlign="left" width={8}>
                  {jobType.length > 0 && (
                    <div>
                      <span className="medium-text">
                        <Icon name="suitcase" />
                        {t("employment_type")}
                        <br />
                      </span>
                      <span
                        className="medium-text"
                        style={{ marginTop: "4px", color: "grey" }}
                      >
                        {jobType.join(",")}
                      </span>
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right" width={8}>
                  {address && (
                    <div>
                      <span className="medium-text">
                        <Icon name="map marker" />
                        {t("address")}
                      </span>
                      <br />
                      <span
                        className="medium-text"
                        style={{ marginTop: "4px", color: "grey" }}
                      >
                        {" "}
                        {address}
                      </span>
                    </div>
                  )}
                </Grid.Column>
              </Grid>
            </Card.Content> */}
            {jobDescription.length > 0 && (
              <Card.Content style={{ textAlign: "left" }}>
                <ReadMore children={jobDescription} />
                {/* <ReactLinkify>{jobDescription}</ReactLinkify> */}
              </Card.Content>
            )}
            <Card.Content style={{ padding: "0px" }}>
              <Carousel
                axis="horizontal"
                showArrows={true}
                showIndicators={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={false}
                swipeable={false}
                useKeyboardArrows={false}
                emulateTouch={false}
                infiniteLoop={false}
              >
                {(job["attachments"] ?? []).map((attachment) => {
                  return (
                    <Grid columns={1}>
                      <Grid.Column>
                        {attachment["contentType"] === "Video" ? (
                          <video
                            src={attachment["fileLink"]}
                            controls
                            style={{ height: "40vh", objectFit: "contain" }}
                          ></video>
                        ) : (
                          <Image
                            centered
                            src={attachment["fileLink"]}
                            style={{ height: "30vh", objectFit: "contain" }}
                          />
                        )}
                      </Grid.Column>
                    </Grid>
                  );
                })}
              </Carousel>
            </Card.Content>
            {/* <Card.Content extra style={{ textAlign: "left" }}>
              {workfields.map((field) => {
                return (
                  <Button
                    basic
                    className="medium-text"
                    style={{ margin: "1px", padding: "0.4rem 1.0rem" }}
                  >
                    {field}
                  </Button>
                );
              })}
            </Card.Content> */}
          </Card>

          {job["status"] === "open" && (
            <div>
              {/* <Container>{t("would_you_like_to_be_contacted")}</Container> */}
              {/* <SpaceElement /> */}
              <MedallaButton
                size="huge"
                fluid
                onClick={() => {
                  pushAction(JOB_STAT_ACTIONS.open_interested);
                  setConsent(1);
                }}
              >
                {t("continue")}
              </MedallaButton>
              <Button
                basic
                fluid
                size="huge"
                onClick={() => {
                  setConsent(2);
                  rejectInvitation();
                }}
              >
                {t("not_interested")}
              </Button>
            </div>
          )}

          {job["status"] !== "open" && (
            <div>
              <Message size="small">
                <p>
                  {t("job_disabled", {
                    name_obj: workplaceDetails["name"],
                  })}
                </p>
              </Message>
              <div style={{ textAlign: "center" }}>
                <Grid centered>
                  <Grid.Column width={isMobile ? 7 : 5}>
                    <DownloadFromAppStore />
                  </Grid.Column>
                  <Grid.Column width={isMobile ? 7 : 5}>
                    <DownloadFromPlayStore />
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

class CandidateForm extends Component {
  warningStatus = false;
  error = null;
  performingRequest = false;
  requestSuccessful = false;

  state = {
    fullName: "",
    email: "",
    phone: "",
    followConsentOne: true,
  };

  componentDidMount = () => {
    const { isJobInvitation, linkData, showProgressOptions } = this.props;
    if (isJobInvitation) {
      this.setState({
        email: linkData["invitationTo"] ?? "",
        followConsentOne: showProgressOptions ? true : false,
      });
    } else {
      this.setState({
        followConsentOne: showProgressOptions ? true : false,
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (e, { name, value }) => {
    this.setState({ [name]: value === 1 });
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);

    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        let value = this.state[key];
        if (typeof value === "string") {
          if (value.trim() === "") {
            valid = false;
          }
        }

        // else if (typeof value === "boolean") {
        //   if (!value) {
        //     valid = false;
        //   }
        // }
      });
    }
    if (!validateContactField(this.state.phone)) {
      valid = false;
    }
    if (!validateEmail(this.state.email)) {
      valid = false;
    }
    return valid;
  };

  validSwedenNumber(phone) {
    //'^(([+]46)\s*(7)|07)[02369]\s*(\d{4})\s*(\d{3})$'

    const regex = new RegExp("^([+]46)s*(7[0236])s*(d{4})s*(d{3})$");
    console.log(regex.test(phone));
    return regex.test(phone);
  }

  showWarning = (name) => {
    let fieldValue = this.state[name];
    if (this.warningStatus && fieldValue.trim() === "") {
      return true;
    }
    if (
      name === "phone" &&
      this.warningStatus &&
      !validateContactField(fieldValue)
    ) {
      return true;
    }
    if (name === "email" && this.warningStatus && !validateEmail(fieldValue)) {
      return true;
    }
    return false;
  };

  postCandidateRequest = async () => {
    const { job, user, openModal, t, pushAction,showProgressOptions } = this.props;

    if (this.checkFormFields()) {
      pushAction(JOB_STAT_ACTIONS.interested);
      this.warningStatus = false;
      this.performingRequest = true;
      this.requestSuccessful = false;
      this.error = null;
      this.setState({});

      try {
        const {
          fullName,
          email,
          phone,
          followConsentOne,
        } = this.state;

        let data = {
          token: user ? user["userId"] : "external",
          bp: "1",
          job: job["jobId"],
          fullName: fullName,
          email: email,
          phone: phone,
          reffererCanFollow: user ? (followConsentOne ? 1 : 0) : 0,
        };

        console.log(showProgressOptions,"IS THEPROGRESS OPTION");
        if (!showProgressOptions) {
          data["referredBy"] = "";
        }

        const response = await axios.post(SUBMIT_JOB_CANDIDATE, data);

        if (response.data["status"] === 0) {
          this.warningStatus = false;
          this.requestSuccessful = true;
        } else {
          const action = response.data["action"];
          openModal(MODAL_CUSTOM_MESSAGE, {
            heading: t("already_submitted"),
            message: response.data["error"],
            actionText: "Download App",
            action: action
              ? () => {
                  const link = action["link"];
                  if (link) {
                    window.open(link, "_blank");
                  }
                }
              : null,
          });
        }
      } catch (e) {
        this.warningStatus = true;
        this.error = t("could_not_complete_request");
      }
    } else {
      this.warningStatus = true;
      this.requestSuccessful = false;
      this.error = t("invalid_information");
    }
    this.performingRequest = false;
    this.setState({});
  };

  render() {
    const { job, user, t, isJobInvitation, showProgressOptions } = this.props;
    const { fullName, email, phone, followConsentOne } = this.state;
    const workplaceDetails = job["workplaceDetails"];
    const imageSize = isMobile ? "15vw" : "70px";
    const logoURL = (workplaceDetails["logoUrl"] ?? "").trim();

    return this.requestSuccessful ? (
      <FinalMessage success={true} user={user} job={job} fullName={fullName} />
    ) : (
      <Segment
        loading={this.performingRequest}
        padded
        basic
        style={{ height: "100vh" }}
      >
        <Grid padded={!isMobile} centered style={{ height: "100%" }} stretched>
          <Grid.Column
            mobile={16}
            computer={8}
            textAlign="left"
            verticalAlign={isMobile ? "top" : "middle"}
          >
            <div>
              <Grid verticalAlign="middle">
                <Grid.Column computer={3} mobile={4}>
                  <Image
                    src={logoURL.length === 0 ? AID_KIT : logoURL}
                    bordered
                    style={{
                      borderRadius: "1.2rem",

                      width: imageSize,
                      height: imageSize,
                      objectFit: "cover",
                      backgroundColor: "#D3D3D3",
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={12} mobile={11}>
                  <div className="medium-text poppins-bold">
                    {workplaceDetails["name"]}
                  </div>
                  <div className="medium-text noto-sans-light">
                    {workplaceDetails["orgName"]}
                    <br />
                    {workplaceDetails["nameVerksamhet"] ?? ""}
                  </div>
                </Grid.Column>
              </Grid>

              <br />
              <div className="medium-text noto-sans-regular" textAlign="left">
                {t("we_appreciate_interest_candidate_form_1")}
                <br />
                {t("we_appreciate_interest_candidate_form_2")}
              </div>
              <br />
              <Form>
                <Form.Field error={this.showWarning("fullName")}>
                  <label className="medium-text noto-sans-regular">
                    {t("full_name")}
                  </label>
                  <input
                    className="medium-text noto-sans-regular"
                    name="fullName"
                    placeholder={t("full_name")}
                    value={fullName}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning("email")}>
                  <label className="medium-text noto-sans-regular">
                    {t("email")}
                  </label>
                  <input
                    className="medium-text noto-sans-regular"
                    name="email"
                    disabled={isJobInvitation}
                    placeholder={t("email")}
                    value={email}
                    type="email"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning("phone")}>
                  <label className="medium-text noto-sans-regular">
                    {t("phone_number")}
                  </label>
                  <input
                    className="medium-text noto-sans-regular"
                    name="phone"
                    placeholder={`${t(
                      "phone_number"
                    )} (+46 7X XXXX XXX / +467XXXXXXXX)`}
                    value={phone}
                    type="tel"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                {user && showProgressOptions && (
                  <Form.Field>
                    <Radio
                      className="medium-text noto-sans-regular"
                      label={t("candidate_consent_checkbox", {
                        firstName: user["firstName"],
                      })}
                      name="followConsentOne"
                      value={1}
                      checked={followConsentOne}
                      onChange={this.handleCheckboxChange}
                    />
                    <br />
                    <Radio
                      className="medium-text noto-sans-regular"
                      label={t("candidate_consent_checkbox_c2")}
                      name="followConsentOne"
                      value={0}
                      checked={!followConsentOne}
                      onChange={this.handleCheckboxChange}
                    />
                  </Form.Field>
                )}
                <br />
                <MedallaButton
                  onClick={this.postCandidateRequest}
                  fluid
                  size="huge"
                >
                  {t("submit")}
                </MedallaButton>
                <br />
                <Container
                  className="medium-text noto-sans-regular"
                  textAlign="center"
                  style={{ fontWidth: "0.8rem" }}
                >
                  {t("candidate_consent_2", {
                    wp_name: workplaceDetails["name"],
                  })}
                </Container>
                {this.error && (
                  <Message negative className="medium-text noto-sans-regular">
                    <Message.Content>{this.error}</Message.Content>
                  </Message>
                )}
              </Form>
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

function FinalMessage(props) {
  const { success, fullName } = props;
  // const workplaceDetails = job["workplaceDetails"];
  // const imageSize = isMobile ? "15vw" : "4vw";
  const { t } = useTranslation();
  return (
    <Segment padded basic style={{ height: "100vh" }}>
      <Grid padded centered style={{ height: "100%" }} stretched>
        <Grid.Column
          mobile={16}
          computer={6}
          textAlign="left"
          verticalAlign="middle"
        >
          <div>
            {/* <Header
              as={isMobile ? "h5" : "h3"}
              textAlign="center"
              style={{ fontWeight: "bold", color: MEDALLA_TEAL }}
            >
              <Image
                src={workplaceDetails["logoUrl"] ?? LOGO_ICON}
                centered
                bordered
                rounded
                style={{
                  // borderRadius: "20vw",
                  width: imageSize,
                  height: imageSize,
                  objectFit: "cover",
                  backgroundColor: "#D3D3D3",
                }}
              />
              {workplaceDetails["name"]}
            </Header> */}

            <a
              href="https://medalla.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={"/assets/logo.png"}
                centered
                style={{ width: isMobile ? "50vw" : "15vw" }}
              />
            </a>
            <br />

            <div
              textAlign="left"
              className="large-text noto-sans-regular"
              style={{ marginTop: "10vh" }}
            >
              {success
                ? t("candidate_submission_positive", {
                    firstName: fullName ?? "",
                  })
                : t("candidate_submission_negative")}
              <br />
              <br />
              {/* {t("thanks_medalla")} */}
            </div>
            {/* <Header as={"h3"} textAlign="center">
              {t("download_app")}
              <br />
              <Header.Subheader>
                {t("job_tips_by_downloading_app")}
              </Header.Subheader>
            </Header> */}
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <Grid centered>
                <Grid.Column width={isMobile ? 7 : 5} textAlign="left">
                  <DownloadFromAppStore />
                </Grid.Column>
                <Grid.Column width={isMobile ? 7 : 5} textAlign="right">
                  <DownloadFromPlayStore />
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
